export const useTokenStore = defineStore('tokenStore', () => {
    let token = ref('');
    const setToken = (tokens: string) => {
        //console.log("settoken", tokens);
        token.value = tokens;
        
        useCookie('token',{maxAge: 60 * 60 * 24 * 30, path: '/'}).value = tokens;
        
    };
    const getToken = () => {
        
        if (!token.value) {
            token.value = useCookie('token').value ?? '';
        }
        //console.log("gettoken", token.value);
        return token.value;
    };
    return {
        setToken,
        getToken,
    };
});