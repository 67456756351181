export default defineNuxtRouteMiddleware((to, from)=>{

    const { reservation, GetSiteSettings } = useSiteSettings();
    GetSiteSettings();
    if(to.path === '/reservation' && !reservation.value){
        return abortNavigation();
    }
    if(to.path === '/posts'){
        return abortNavigation();
    }
})