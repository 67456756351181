export const useToast = defineStore('toastStore', () => {
    interface ToastMessage {
        message: string;
        type: string;
    }
    const toastMessages = ref<ToastMessage[]>([]);
    const timeout = 5000;
    const success = (message: string) => {
        addMessage(message, 'success');
    };
    const error = (message: string) => {
        addMessage(message, 'error');
    };
    const warning = (message: string) => {
        addMessage(message, 'warning');
    };
    const info = (message: string) => {
        addMessage(message, 'info');
    };
    const addMessage = (message: string, type : string) => {
        toastMessages.value.push({
            message: message,
            type: type,
        });
        
        setTimeout(() => {
            toastMessages.value.shift();
        }, timeout);
    };
    const clearMessage = () => {
        toastMessages.value.shift();
    };
    return {
        toastMessages,
        success,
        error,
        warning,
        info,
        clearMessage,
    };
});