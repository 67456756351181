import validate from "/saham/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/saham/middleware/setup.global.ts";
import manifest_45route_45rule from "/saham/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/saham/middleware/auth.ts")
}