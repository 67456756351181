<template>
  <NuxtLayout>
    <div v-if="toastMessages" class="toast toast-end z-[1]">
      <template v-for="data in toastMessages">
        <div v-if="data.message" class="flex justify-between max-w-sm alert" :class="`alert-${data.type}`">
          <div class="text-wrap">
            {{data.message}}
          </div>
          <div>
            <button @click="clearMessage" class="btn btn-sm btn-circle">
              <Icon name="x"/>
            </button>
          </div>
        </div>
      </template>
    </div>
    <NuxtLoadingIndicator/>
    <NuxtPage/>
  </NuxtLayout>
</template>
<script setup lang="ts">
const uploadUrl = useRuntimeConfig().public.uploadUrl;

useHead(
  {
    htmlAttrs: {
      'data-theme': 'light'
    },
    link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: uploadUrl+'/images/site/site_icon.png'
    }
  ],
  }
)
// get toast messages from useToastMessages composable function and clear them 
const { toastMessages, clearMessage } = useToast()
var backgroundImage = useRuntimeConfig().public.uploadUrl +'/images/site/site_background.png';
  
  onMounted(() => {
    
    //set custom background css property to id _nuxt
    document.getElementById('__nuxt').style.backgroundImage = `url(${backgroundImage})`;
    document.getElementById('__nuxt').style.backgroundPosition = 'center center';
    document.getElementById('__nuxt').style.backgroundSize = 'cover';
    document.getElementById('__nuxt').style.backgroundRepeat = 'no-repeat';
    document.getElementById('__nuxt').style.backgroundAttachment = 'fixed';
  });
  

</script>